import axios from 'axios';
import Cookies from 'js-cookie';
// const apiKey = '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';
const apiKey = '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';


// const apiUrl = 'https://api.openai.com/v1/chat/completions';
// const apiUrl = 'https://api.openai.com/v1/dalle-generate'; //error
const apiUrl = 'https://api.openai.com/v1/images/generations';
// const apiUrl = 'https://api.openai.com/v1/images/edits';
const apivURL = 'https://api.openai.com/v1/audio/speech';
//const [audioSrc, setAudioSrc] = useState('1');
const promises = [];
const resdata = [];
const genImage = [];

const resType = false

export const generateImaged3 = async (formData) => {

    try {
        const mergerequest = formData.question + " it's about " + formData.qabout + " Do not include any text inside the images";
        if (resType) {
            for (let i = 0; i < 3; i++) {
                promises.push(
                    axios.post(
                        apiUrl,
                        {
                            model: 'dall-e-2',
                            prompt: mergerequest,
                            size: "1024x1024",
                            n: 1

                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${apiKey}`
                            },
                        }
                    )
                );
            } // for end

        } else {
            for (let i = 0; i < 3; i++) {
                promises.push(
                    /** rr :: start **/
                    {
                        "data": {
                            "created": 1707660487,
                            "data": [
                                {
                                    "url": "https://staging.yatter.io/webs/mp3s/genrate_images/sampleyatter.png"
                                }
                            ]
                        }
                    }
                    /** rr :: end **/
                    /** rr :: start remove**/
                    /*axios.post(
                        apiUrl,
                        {
                            model: 'dall-e-2',
                            prompt: mergerequest,
                            size: "512x512",
                            n: 1
        
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${apiKey}`
                            },
                        }
                    )*/
                    /** rr :: end remove**/
                );
            }
        }


        const responses = await Promise.all(promises);
        //const data = await Promise.all(responses.map(res => res.json()));

        // console.log('results are ');
        // console.log(responses);

        for (let i = 0; i < responses.length; i++) {

            resdata.push(responses[i].data.data[0]);
        }

        //console.log('res  are ');
        //console.log(resdata);
        //  Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
        Cookies.set('scripttxt', formData.question);
        return resdata;





        /* Promise.all(promises)
             .then(response => {
                 console.log('results are ');
             console.log(response);
             return response;
             })
             .catch(error => {
                 console.error('Error generating image:', error);
                 return null;
         });*/
    } catch (error) {
        console.error('Error generating image:', error);
        return null;
    }

    /*
    try {
        const response = await axios.post(
            apiUrl,
            {
                model: 'dall-e-3',
                prompt: formData.question,
                size: "1024x1024",
                n: 1

            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
            }
        );
    //console.log(response);
        if (response?.data) {

           // return response?.data?.data?.[0]?.url
           Cookies.set('scripttxt', formData.question);
           return response?.data?.data
        }


    } catch (error) {
        console.error('Error generating image:', error);
        return null;
    }*/
};




export const generateImage = async (formData) => {
    // console.log(formData);
    try {
        const mergerequest = formData.question + " it's about " + formData.qabout + " Do not include any text inside the images";
        if (resType) {
            const response = await axios.post(
                apiUrl,
                {
                    model: 'dall-e-2',
                    prompt: mergerequest,
                    size: "1024x1024",
                    n: 1

                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                    },

                }
            );
            //console.log(response);
            if (response?.data) {

                // return response?.data?.data?.[0]?.url
                // Cookies.set('scripttxt', formData.question);
                Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
                return response?.data?.data
            }
        }
        else {
            // const response = '';
            genImage.push(

                {
                    // "data": {
                    "created": 1707660487,
                    "data": [
                        {
                            "url": "https://staging.yatter.io/webs/mp3s/genrate_images/sampleyatter.png"
                            // "url": "https://yatter.ai/wp-content/uploads/2024/02/Official-Yatter-Logo.png"
                        }
                    ]
                }
                // }

            );
            const respons = await Promise.all(genImage);
            console.log('eeeeeee', respons[0]?.data[0]);
            // for (let i = 0; i < response.length; i++) {

            resdata.push(respons[0]?.data[0]);
            console.log('sanpo', formData);
            Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
            // Cookies.set('scripttxt', formData.question);
            return resdata;
            // if (response?.data) {

            //     // return response?.data?.data?.[0]?.url
            //     Cookies.set('scripttxt', formData.question);
            //     return response?.data?.data
            // }
        }
        // //console.log(response);
        // if (response?.data) {

        //     // return response?.data?.data?.[0]?.url
        //     Cookies.set('scripttxt', formData.question);
        //     return response?.data?.data
        // }


        console.log('res  are ', resdata);
    } catch (error) {
        console.error('Error generating image:', error);
    };
    return null;
}


export const generateVoice = async (formData) => {
    // console.log(formData.question);

    try {
        const response = await axios.post(
            apivURL,
            {
                model: 'tts-1',
                //  prompt: formData.question,
                input: "Do it now and again",
                voice: "onyx",
                speed: 1

            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
            }
        );
        // console.log(response.data);
        if (response?.data) {

            // return response?.data?.data?.[0]?.url
            /* const audioBlob = response.data;
             const audioUrl = URL.createObjectURL(audioBlob);*/
            //setAudioSrc(audioUrl);
            return response?.data;
        }


    } catch (error) {
        console.error('Error generating image:', error);
        return null;
    }
};
