import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetAudiofromWebService } from './services/SendImageService';
import Cookies from 'js-cookie';
import Switch from 'react-switch';

function GetAudio() {
  const [AudioSrc, setAudioSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const [voice, setVoice] = useState(true);
  const [music, setMusic] = useState(false);
  const [count, setCount] = useState(0);
  const [volume, setVolume] = useState(50); // Default volume is 100%
  const [whiteparticles, setWhiteParticles] = useState(false);
  const [playmusic, setPlayMusic] = useState(false);
  const [caption, setCaption] = useState({
    caption_switch: true,
    caption_color: "#ff0000"
  });

  // const [selectedItem, setSelectedItem] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedId, setSelectId] = useState();

  // const audioData = [
  //   { id: 1, src: 'https://actions.google.com/sounds/v1/alarms/alarm_clock.ogg', name: 'Epic Battle Music' },
  //   { id: 2, src: 'http://webaudioapi.com/samples/audio-tag/chrono.mp3', name: 'Transquility-Anno Domini...' },
  //   { id: 3, src: 'https://staging.yatter.io/webs/mp3s/backmusic3.mp3', name: 'Forever-Anno Be...' },
  //   { id: 4, src: 'https://staging.yatter.io/webs/mp3s/backmusic4.mp3', name: 'Heaven and Hell-Jeremy...' },
  // ];
  const audioData = [
    { id: 1, src: 'https://staging.yatter.io/webs/mp3s/backmusic1.mp3', name: 'Epic Battle Music' },
    { id: 2, src: 'https://staging.yatter.io/webs/mp3s/backmusic2.mp3', name: 'Transquility-Anno Domini...' },
    { id: 3, src: 'https://staging.yatter.io/webs/mp3s/backmusic3.mp3', name: 'Forever-Anno Be...' },
    { id: 4, src: 'https://staging.yatter.io/webs/mp3s/backmusic4.mp3', name: 'Heaven and Hell-Jeremy...' },
  ];

  // const bgMusic_data = [
  //   {
  //     id: 1,
  //     audioSrc: 'https://staging.yatter.io/webs/mp3s/staticaudio1.mp3',
  //     voiceName: 'Epic Battle Music',
  //   },
  //   {
  //     id: 2,
  //     audioSrc: 'https://pixabay.com/music/synthwave-stranger-things-124008/',
  //     voiceName: 'Transquility-Anno Domini...',
  //   },
  //   {
  //     id: 3,
  //     audioSrc: 'https://staging.yatter.io/webs/mp3s/staticaudio2.mp3',
  //     voiceName: 'Forever-Anno Domini Be...',
  //   },
  //   {
  //     id: 4,
  //     audioSrc: 'https://staging.yatter.io/webs/mp3s/staticaudio2.mp3',
  //     voiceName: 'Heaven and Hell-Jeremy...',
  //   },
  // ]
  // Handler for when the selection changes

  const location = useLocation();
  const navigate = useNavigate();
  console.log(location);
  const items = location.state.items || []; // Accessing data passed from Screen 1
  // console.log(items);
  /* const data = {
     key: '850Q',
     // Other data to send
   };
 */
  //GetAudiofromWebService ("You are IN");

  useEffect(() => {

    const loadData = async () => {
      const scriptout = Cookies.get('scripttxt');
      console.log('cookie in getaudio');
      console.log(scriptout);
      setLoading(true);
      const result = await GetAudiofromWebService(scriptout);
      console.log('result is');
      //  console.log(result);
      if (result) {
        setLoading(false);
        setCount(result);
      }
    }

    loadData();

    //const goin=GetAudiofromWebService ("You are IN");
    //console.log('go inhere');
    //console.log(goin);
    // setCount(goin); // Initialize or perform a side effect once
  }, []); // Empty dependency array

  /* setLoading(true);

   useEffect(() => {
       const audiores=GetAudiofromWebService('here you got');
       if(audiores)
       {
       setAudioSrc(audiores);
       setLoading(false);
       }
     }, []); // Empty dependency array
*/
  /*  const audiores=GetAudiofromWebService('here you got');
    if(audiores)
    {
    setAudioSrc(audiores);
    setLoading(false);
    }*/


  const navigateToVideo = () => {
    console.log('items are :');
    console.log(items);

    // const newKey = Math.max(...Object.keys(items).map(Number)) + 1;
    const numericKeys = Object.keys(items).filter(key => !isNaN(Number(key)));
    const newKey = numericKeys.length > 0 ? Math.max(...numericKeys.map(Number)) + 1 : 0;
    let newlink = { audio: count };
    items[newKey] = newlink;
    // const updatedValue = { ...items, bgMusicData: { music_name: selectedValue.audioSrc, voice_switch: voice } };
    const updatedValue = {
      ...items,
      bgMusicData: { music_name: AudioSrc, music_switch: music, Volume: volume },
      white_particles: whiteparticles,
      voice: voice,
      Caption: caption
    };
    console.log('getaudioupdated', updatedValue)
    const Audio = { audio: count };
    // const updatedItems = { ...items, Audio };
    console.log('updated items are :', items);
    // console.log(items);
    //  const dataToPass = { items };
    audioRefff?.current?.pause();
    navigate('/GetVideo', { state: { items: updatedValue } });
  };

  const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state
  const audioRef = useRef(null); // Reference to the audio element
  const audioRefff = useRef(null); // Reference to the audio element
  // const audioRefff = useRef(new Audio());

  const togglePlayPause = (e) => {
    e.preventDefault();
    console.log('check audio ref');
    if (audioRefff.current) {
      audioRefff.current.pause()
    }
    console.log('inside view', isPlaying);
    const audio = audioRef.current;
    console.log('audio', audio);
    // audio.play();
    if (isPlaying) {
      audio.pause();
      setIsPlaying(false);
    } else {
      audio.play();
      setIsPlaying(true);
    }
    setIsPlaying(!isPlaying); // Toggle the state
  };
  // const togglePlayaudio = (id, src) => {
  //   if (selectedId === id) {
  //     // Pause if the same audio is clicked again
  //     if (audioRefff.current.paused) {
  //       audioRefff.current.play();
  //     } else {
  //       audioRefff.current.pause();
  //     }
  //   } else {
  //     // Play a new audio and pause the currently playing one
  //     if (audioRefff.current) {
  //       audioRefff.current.pause();
  //     }
  //     audioRefff.current = new Audio(src);
  //     audioRefff.current.play();
  //     setSelectId(id);
  //     setAudioSrc(src);
  //   }
  // };

  const togglePlayaudio = (event) => {
    setSelectId(event.target.value);

  };

  useEffect(() => {
    if (selectedId) {
      const selectedTrack = audioData.find(track => track.id.toString() === selectedId);
      if (selectedTrack) {
        if (audioRef.current) {
          audioRef?.current?.pause()
        }
        const audio = audioRefff.current;
        audio.src = selectedTrack.src;
        // console.log(audio.src);
        setAudioSrc(audio?.src)
        setPlayMusic(true)
        audio.play().catch(error => console.error("Error playing the audio:", error));
      }
    }
  }, [selectedId]); // This effect runs when selectedId changes.


  // console.log('audioref', audioRef);

  let currentAudio = null;
  let currentAudioElement = null;

  const togglePlay = (item, index) => {
    // const selectedAudioUrl = item.audioSrc;
    // // setSelectedValue(selectedAudioUrl);
    // console.log('selectedAudioUrl', selectedAudioUrl);
    // // Play the selected audio
    // if (selectedAudioUrl) {
    //   const audio = new Audio(selectedAudioUrl);
    //   audio.play().catch(error => console.error("Error playing the audio file:", error));
    // }

    // const currentAudio = audioRefff.current[index].current;
    // const currentAudio = audioRefff.current;
    // console.log('currentAudio', currentAudio);
    // currentAudio.play();

    const selectedAudioUrl = item.audioSrc;
    console.log('selectedAudioUrl', selectedAudioUrl);

    // Stop the currently playing audio
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset audio to start
    }

    // If the selected item is the same as the currently playing, just stop and reset
    if (currentAudioElement && currentAudioElement.id === `audioPlayer-${item.id}`) {
      currentAudio = null;
      currentAudioElement = null;
      return;
    }

    // Play the selected audio
    const audioElement = document.getElementById(`audioPlayer-${item.id}`);
    if (audioElement) {
      audioElement.play().then(() => {
        console.log("Audio is playing");
        currentAudio = audioElement;
        currentAudioElement = audioElement;
      }).catch(error => {
        console.error("Error playing the audio file:", error);
        // Handle autoplay restriction or other errors
        // For example, you could show a message asking the user to click again
      });
    }

  };


  const handleVoice = (e) => {
    setVoice(!voice);
  }

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
  };

  const handleCheckboxChange = () => {
    setCaption(prevState => ({
      ...prevState,
      caption_switch: !prevState.caption_switch
    }));
  };

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    setCaption(prevState => ({
      ...prevState,
      caption_color: newColor
    }));
  };


  useEffect(() => {
    if (audioRefff.current) {
      // audioRefff.current.volume = volume;
      audioRefff.current.volume = volume / 100;
    }
  }, [volume]);


  // console.log(caption);

  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  //   const selectedAudioUrl = event.target.value;
  //   setSelectedValue(selectedAudioUrl);

  //   // Play the selected audio
  //   if (selectedAudioUrl) {
  //     const audio = new Audio(selectedAudioUrl);
  //     audio.play().catch(error => console.error("Error playing the audio file:", error));
  //   }
  // };



  const handlePlayPausedd = () => {
    if (selectedId) {
      const selectedTrack = audioData.find(track => track.id.toString() === selectedId);
      if (selectedTrack) {
        if (audioRef.current) {
          audioRef.current.pause();
        }
        const audio = audioRefff.current;
        if (playmusic) {
          audio.pause();
          setPlayMusic(false); // Pause music, update state
        } else {
          audio.play();
          setPlayMusic(true); // Start playing music, update state
        }
      }
    }
  };



  return (
    <>

      <div class="creator-wrapper">
        <h3 class="mb-4">AI Voice</h3>

        <div class="progress-steps">

          <div class="step-block completed"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ cursor: hover && 'pointer' }}
            onClick={() => {
              hover && navigate('/GetScript')
              localStorage.removeItem('imagePageState');

            }}
          >
            <span class="step-label" style={{ color: hover && '#F11D28' }}>1. Choose Script</span>
            <span class="step-indicator" style={{ transform: hover && 'scale(1.06)' }}> <span class="material-icons check-icon">check</span></span>
          </div>
          <div class="step-block completed"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ cursor: hover && 'pointer' }}
            onClick={() => {
              hover && navigate('/GetImages')
              // localStorage.removeItem('imagePageState');
              // audioRefff.current.pause();
            }}
          >
            <span class="step-label" style={{ color: hover && '#F11D28' }}>2. Choose Image</span>
            <span class="step-indicator" style={{ transform: hover && 'scale(1.06)' }}><span class="material-icons check-icon">check</span></span>
          </div>
          <div class="step-block active">
            <span class="step-label">3. Choose Video</span>
            <span class="step-indicator"><span class="material-icons check-icon">check</span></span>
          </div>

        </div>

        <form class="creator-form" action="#">

          <div class="checkbox-container">

            <div class="flex items-center">
              <input
                class="w-4 h-4 bg-gradient rounded"
                type="checkbox"
                id='short-voice'
                onChange={handleVoice}
                checked={voice}

              />
              <label for="short-voice" class="ms-2">Voice</label>
            </div>

            {
              voice &&

              <div class="voices">
                <div class="voice-block selected">
                  {/*  <audio ref={audioRef} id='audioPlayer' src={count}/> */}
                  <audio ref={audioRef} id='audioPlayer' src={'https://staging.yatter.io/webs/mp3s/staticaudio.mp3'} onEnded={() => setIsPlaying(false)} />
                  {loading ? '' :
                    <a class="voice-play" onClick={togglePlayPause}>
                      {isPlaying ? <span class="material-icons check-icon">pause</span> :
                        <span class="material-symbols-outlined">play_circle
                        </span>}
                    </a>
                  }
                  {/*} <a class="voice-play" onClick={togglePlayPause}>{isPlaying ? 'play' : 'pause'}<span class="material-symbols-outlined">play_circle</span></a>*/}
                  <span class="voice-name">Male - Onyx <span class="material-icons check-icon">check</span></span>
                </div>

                {/* <audio ref={audioRef} src={count}></audio>
      <a onClick={togglePlayPause}>
        <a class="voice-play" onClick={togglePlayPause}>{isPlaying ? 'Pause' : 'Play'}<span class="material-symbols-outlined">play_circle</span></a>
  </a>*/}

              </div>

            }

          </div>


          {/* <div className='flex mb-2'>
            <label htmlFor="insert-qabout" className="caption">Background Music</label>
            <Switch
              onChange={() => setMusic(!music)}
              checked={music}
              onColor='#FFA12C'
              onHandleColor="#fffff"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={18}
              width={48}
              className="react-switch"
              id="material-switch"
            />
          </div> */}

          <div class="checkbox-container">
            <div class="flex items-center">
              <input id="short-bg-music"
                type="checkbox"
                value={music}
                onChange={() => setMusic(!music)}
                class="w-4 h-4 bg-gradient rounded"
              />
              <label for="short-bg-music" class="ms-2">Background Music</label>
            </div>

            <div class="bg-music">
              {
                music &&
                <>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    {/* {
                      selectedId&&
                    <span className="material-icons check-icon" style={{ cursor: "pointer" }} onClick={handlePlayPause}>
                      {playmusic ? 'pause' : 'play_circle'}
                    </span>
} */}
                    {
                      AudioSrc&&
                    <span className="material-icons " style={{ cursor: "pointer" }}
                      onClick={handlePlayPausedd}
                    >
                      {playmusic ? 'pause' : 'play_circle'}
                    </span>
                    }
                    <select
                      className='input-control'
                      value={selectedId}

                      onChange={togglePlayaudio}

                    >
                      <option selected value="">Select Music</option>
                      {audioData.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </select>
                    <audio ref={audioRefff}  onEnded={() => setPlayMusic(false)}/>
                  </div>
                  <div class="flex items-center">
                    <span class="text-gray mr-4">Volume:</span>
                    <div class="volume-control mr-4">
                      <input
                        type="range"
                        min="1"
                        max="100"
                        value={volume}
                        class="slider-volume"
                        onChange={handleVolumeChange}
                      />
                    </div>
                    <span class="font-medium">{volume}</span>
                  </div>

                </>
              }


            </div>

          </div>

          {/* <div class="checkbox-container">
            <div class="flex items-center">
              <input
                id="short-caption"
                type="checkbox"
                class="w-4 h-4 bg-gradient rounded"
                checked={whiteparticles}
                onChange={() => setWhiteParticles(!whiteparticles)}
              />
              <label for="short-caption" class="ms-2">White particles</label>
            </div>
          </div> */}

          <div class="checkbox-container">
            <div class="flex items-center">
              <input
                id="short-caption"
                type="checkbox"
                class="w-4 h-4 bg-gradient rounded"
                checked={caption.caption_switch}
                onChange={handleCheckboxChange}
              />
              <label for="short-caption" class="ms-2">Captions</label>
            </div>

            {/* <div class="captions">
              <div class="flex items-center">
                <span class="text-gray mr-4">Caption color:</span>
                <input
                  id='favcolor'
                  name="caption_color"
                  value={caption.caption_color}
                  type='color'
                  onChange={handleColorChange}
                // onChange={(e) => setCaption({ ...caption, [e.target.name]: e.target.value })}
                />
              </div>
            </div> */}
          </div>

          {/* {
            music &&
            <>
              {audioData.map(({ id, src, name }) => (
                <div key={id} className={`voices ${selectedId === id ? 'selected' : ''}`}>
                  <div
                    // className="voice-block"
                    className={`voice-block ${selectedId === id ? 'selected' : ''}`}
                  >
                    <audio ref={audioRefff} src={src} />
                    <a className="voice-play"
                      onClick={() => togglePlayaudio(id, src)}
                    >
                      <span className="material-symbols-outlined">play_circle</span>
                    </a>
                    <span className="voice-name">{name} <span className="material-icons check-icon">check</span></span>
                  </div>
                </div>
              ))}

            </>
          } */}

          {/* <div>
          <span class="text-gray mr-4">Volume:</span>
            <input
              id="volumeControl"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
                 <span class="font-medium">{volume}</span>
          </div> */}
          {/* <div class="flex items-center">
            <span class="text-gray mr-4">Volume:</span>
            <div class="volume-control mr-4">
              <input type="range" min="1" max="100" value="50" class="slider-volume" id="myRange" />
            </div>
            <span class="font-medium">50</span>
          </div> */}
          {loading ? <div className="loader"></div> : <button type="button" class="btn btn-gradient" onClick={navigateToVideo}>Generate short</button>}
        </form >

      </div >

      <div class="example-part">
        <h6 className="mb-2">AI Example</h6>
        <div className="example-media">
          {/* <img className="media-file" src={`${process.env.PUBLIC_URL}/images/ai-example.jpg`} alt="Example media"/> */}
          <video width="320" height="240" controls>
            <source src={`${process.env.PUBLIC_URL}/images/TheMaryCeleste.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

    </>
  );
}

export default GetAudio;
