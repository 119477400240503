import axios from 'axios';
import Cookies from 'js-cookie';
import { fileFromPath } from 'openai';

const apiKey = 'b5aee004-3ea7-4a63-b042-37de2a7e73bb';

const customApi = axios.create({
    baseURL: 'https://cloud.leonardo.ai/',
});

const promises = [];
const resdata = [];
const genImage = [];

const resType = true

export const imageGenerateID = async (data) => {
    // console.log('prompt', data?.prompt);
    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Authorization': `Bearer ${apiKey}`// Replace YOUR_ACCESS_TOKEN with your actual access token
        },
        data: {
            height: 1024,
            prompt: data.prompt,
            width: 1024,
            highResolution: true,
            // photoReal: true,
            alchemy: true,
            // imagePrompts: data.prompt,
            num_images: 1,
            // init_strength: 0.9, 
            highResolution: true,
            photoReal: true
        }
    };
    if (resType) {
        const response = await axios.post('https://cloud.leonardo.ai/api/rest/v1/generations', options.data, {
            headers: options.headers
        });
        if (response.status === 200) {
            // console.log('resp', response);
            // console.log('respo', response.data.sdGenerationJob?.generationId);
            Cookies.set('scripttxt', data.prompt);
            //  Cookies.set('scripttxt', formData.question, { path: '/', domain: '.yatter.io' });
            return response.data.sdGenerationJob?.generationId
        } else {
            console.log('respo_err', response);
        }

    }
    else {

        // const response = '';
        genImage.push(

            {
                "data": {
                    "sdGenerationJob": {
                        "generationId": "df8fdb8f-82e2-4036-8b6b-aae0d5458384",
                        "apiCreditCost": 7
                    }
                },
                "status": 200,
                "statusText": "",
                "headers": {
                    "content-type": "application/json; charset=utf-8"
                },
                "config": {
                    "transitional": {
                        "silentJSONParsing": true,
                        "forcedJSONParsing": true,
                        "clarifyTimeoutError": false
                    },
                    "adapter": [
                        "xhr",
                        "http"
                    ],
                    "transformRequest": [
                        null
                    ],
                    "transformResponse": [
                        null
                    ],
                    "timeout": 0,
                    "xsrfCookieName": "XSRF-TOKEN",
                    "xsrfHeaderName": "X-XSRF-TOKEN",
                    "maxContentLength": -1,
                    "maxBodyLength": -1,
                    "env": {},
                    "headers": {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer b5aee004-3ea7-4a63-b042-37de2a7e73bb"
                    },
                    "method": "post",
                    "url": "https://cloud.leonardo.ai/api/rest/v1/generations",
                    "data": "{\"height\":1024,\"prompt\":\"flower\",\"width\":1024,\"num_images\":1}"
                },
                "request": {}
            }
            // }

        );
        const respons = await Promise.all(genImage);
        // console.log('check', respons?.[0].status);
        // console.log('check', respons?.[0]?.data.sdGenerationJob?.generationId);
        // for (let i = 0; i < response.length; i++) {
        if (respons?.[0].status === 200) {

            // console.log('sanpo', data.prompt);
            Cookies.set('scripttxt', data.prompt, { path: '/', domain: '.yatter.io' });
            // Cookies.set('scripttxt', data.prompt);
            return respons?.[0]?.data.sdGenerationJob?.generationId;
        } else {
            console.log('respo_err1', respons);
        }
        // resdata.push(respons);
        // if (response?.data) {

        //     // return response?.data?.data?.[0]?.url
        //     Cookies.set('scripttxt', formData.question);
        //     return response?.data?.data
        // }
    }
}



// export const imageGenerate = async (id) => {
//     console.log('id', id);
//     const options = {
//         method: 'GET',
//         headers: {
//             accept: 'application/json',
//             'Authorization': `Bearer ${apiKey}`,// Replace YOUR_ACCESS_TOKEN with your actual access token
//         }
//     };

//     try {
//         const response = await axios.get(`https://cloud.leonardo.ai/api/rest/v1/generations/${id}`, {
//             headers: {
//                 accept: 'application/json',
//                 'Authorization': `Bearer ${apiKey}`,// Replace YOUR_ACCESS_TOKEN with your actual access token
//             }
//         });
//         console.log('image', response.data);
//         return response.data
//     } catch (error) {
//         console.error(error);
//     }
// }


export const imageGenerate = async (id) => {
    console.log('iddd', id);
    if (resType) {

        try {
            // const { data } = await customApi.get(`api/rest/v1/generations/36e463d7-dd34-41e2-8641-3256f7722f39`, {
            const { data } = await customApi.get(`api/rest/v1/generations/${id}`, {
                headers: {
                    accept: 'application/json',
                    'Authorization': `Bearer ${apiKey}`,// Replace YOUR_ACCESS_TOKEN with your actual access token
                }
            });
            console.log('resp', data);
            return data
        } catch (error) {
            return error.response.data
        }
    }
    else {

        // const response = '';
        resdata.push(

            {
                "generations_by_pk": {
                    "generated_images": [
                        {
                            "url": "https://staging.yatter.io/webs/mp3s/genrate_images/sampleyatter.png",
                            "nsfw": false,
                            "id": "3f23efa6-99c6-4f6c-801f-b948d4418690",
                            "likeCount": 0,
                            "motionMP4URL": null,
                            "generated_image_variation_generics": []
                        }
                    ],
                    "modelId": null,
                    "motion": null,
                    "motionModel": null,
                    "motionStrength": null,
                    "prompt": "car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy car with guy",
                    "negativePrompt": "",
                    "imageHeight": 1024,
                    "imageToVideo": null,
                    "imageWidth": 1024,
                    "inferenceSteps": 15,
                    "seed": 588617216,
                    "public": false,
                    "scheduler": "EULER_DISCRETE",
                    "sdVersion": "v1_5",
                    "status": "COMPLETE",
                    "presetStyle": null,
                    "initStrength": null,
                    "guidanceScale": 7,
                    "id": "b426e1ad-7420-48af-871c-a97ca57805c3",
                    "createdAt": "2024-02-23T13:26:45.348",
                    "promptMagic": false,
                    "promptMagicVersion": null,
                    "promptMagicStrength": null,
                    "photoReal": false,
                    "photoRealStrength": null,
                    "fantasyAvatar": null,
                    "generation_elements": []
                }
            }

        );
        const respons = await Promise.all(resdata);
        if (respons) {
            console.log('check_trial', respons);
            return respons?.[0]
        } else {
            console.log('error2');
        }
        // for (let i = 0; i < response.length; i++) {

        // resdata.push(respons);

        return respons;
        // if (response?.data) {

        //     // return response?.data?.data?.[0]?.url
        //     Cookies.set('scripttxt', formData.question);
        //     return response?.data?.data
        // }
    }

}

export const getUploadImageId = async (image) => {
    return console.log(fileFromPath);
    try {
        const authorization = `Bearer ${apiKey}`;

        const headers = {
            accept: "application/json",
            "content-type": "application/json",
            authorization: authorization,
        };

        // Get a presigned URL for uploading an image
        const initImageUrl = "https://cloud.leonardo.ai/api/rest/v1/init-image";
        const initImagePayload = { extension: "jpg" };

        const initImageResponse = await axios.post(initImageUrl, initImagePayload, { headers });
        console.log("Get a presigned URL for uploading an image:", initImageResponse);

        // Upload image via presigned URL
        // const { fields, url: uploadUrl, id: image_id } = initImageResponse.data.uploadInitImage;
        // const imageFilePath = image;
        //   const imageFilePath = "/workspace/test.jpg";
        const formData = new FormData();
        formData.append('file', image);

        const response = await axios.post(initImageResponse.data.uploadInitImage.url, formData, initImageResponse.data.uploadInitImage.fields);
        //    const response =   await axios.post(uploadUrl, formData, { params: fields });

        console.log("Upload image via presigned URL: Image uploaded successfully", response);

        // Generate with Image to Image
        const generationUrl = "https://cloud.leonardo.ai/api/rest/v1/generations";
        const generationPayload = {
            height: 512,
            modelId: "1e60896f-3c26-4296-8ecc-53e2afecc132",
            prompt: "An oil painting of a cat",
            width: 512,
            init_image_id: initImageResponse.data.uploadInitImage.id,
            init_strength: 0.5,
        };

        const generationResponse = await axios.post(generationUrl, generationPayload, { headers });
        console.log("Generation of Images using Image to Image:", generationResponse);

        const generationId = generationResponse.data.sdGenerationJob.generationId;

        const generationStatusUrl = `https://cloud.leonardo.ai/api/rest/v1/generations/${generationId}`;

        setTimeout(async () => {
            const response = await axios.get(generationStatusUrl, { headers });
            console.log(response.data);
        }, 60000); // Wait for 20 seconds before checking generation status
    } catch (error) {
        console.error("Error:", error);
    }
};

// export const getUploadImageId = async (image) => {


//     const authorization = `Bearer ${apiKey}`;

//     const headers = {
//         "accept": "application/json",
//         "content-type": "application/json",
//         "authorization": authorization
//     };

//     // Get a presigned URL for uploading an image
//     const initImageUrl = "https://cloud.leonardo.ai/api/rest/v1/init-image";
//     const initImagePayload = { extension: "jpg" };

//     axios.post(initImageUrl, initImagePayload, { headers })
//         .then(response => {
//             const { uploadInitImage } = response.data;
//             const { fields, url, id } = uploadInitImage;
//             // const imageFilePath = "C:/Users/dgpt1/Downloads/pngtree-jpg-file-document-icon-png-image_897364.jpg";
//             // const file = fs.createReadStream(imageFilePath);
//             formData.append('file', image);
//             // image
//             const formData = new FormData();

//             Object.entries(fields).forEach(([key, value]) => {
//                 formData.append(key, value);
//             });


//             // Upload image via presigned URL
//             return axios.post(url, formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data'
//                 }
//             }).then(() => id);
//         })
//         .then(imageId => {
//             // Generate video with an init image
//             const generateVideoUrl = "https://cloud.leonardo.ai/api/rest/v1/generations-motion-svd";
//             const payload = {
//                 imageId: imageId,
//                 isInitImage: true,
//                 motionStrength: 5
//             };

//             return axios.post(generateVideoUrl, payload, { headers })
//                 .then(response => response.data.motionSvdGenerationJob.generationId);
//         })
//         .then(generationId => {
//             // Get the generated video
//             const generationUrl = `https://cloud.leonardo.ai/api/rest/v1/generations/${generationId}`;

//             return new Promise(resolve => {
//                 setTimeout(() => {
//                     resolve(axios.get(generationUrl, { headers }));
//                 }, 60000);
//             });
//         })
//         .then(response => {
//             console.log(response.data);
//         })
//         .catch(error => {
//             console.error('Error:', error);
//         });

// };

