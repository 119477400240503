import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { generateImage } from './services/chatGPTService';
import { ImageModal } from './ImageModal';
import Modal from './Modal';
import trash from './images/trash.svg';
import { uploadImageGenerate } from './services/SendImageService';
import { getUploadImage, getUploadImageId, imageGenerate, imageGenerateID } from './services/leonardoService';
import { type } from '@testing-library/user-event/dist/type';

function GetImages() {

  const location = useLocation();

  const formDataString = localStorage.getItem('getFormData');

  const getformData = formDataString ? JSON.parse(formDataString) : {};

  // console.log('location', location);
  // const { subFormData } = location.state || {};
  const navigate = useNavigate();
  // const items = null;
  //let items;
  const [items, setitems] = useState([]);
  const [regeneratecount, setregeneratecount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [modalImage, setModalImage] = useState(null);
  const [selectedImg, setSelectedImg] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [addhoveredIndex, setAddHoveredIndex] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadAddProgress, setUploadAddProgress] = useState(null);
  const [regenProgress, setReGenProgress] = useState(null);
  const [regenerate, setRegenerate] = useState(null);
  const [regenerateLoader, setRegenerateLoader] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(null);
  const [uploadSelfLoader, setUploadSelfLoader] = useState(null);
  const [uploadSelfProgress, setUploadSelfProgress] = useState(null);
  const [uploadAddLoader, setUploadAddLoader] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [timecompleted, settimeCompleted] = useState(false);
  const [firstRegenerate, setFirstRegenerate] = useState(false);
  const [hover, setHover] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [progress, setProgress] = useState(0);
  const [checkData, setCheckData] = useState();
  const [imageLimit, setImageLimit] = useState(false);



  const handleFileChange = async (event) => {
    // console.log('check', location?.state?.subFormData?.question);
    setUploadSelfLoader(true)
    setFirstRegenerate(true)
    const file = event.target?.files[0];
    console.log('nisaldhpowe', file);
    let data = new FormData();
    data.append('image', file);
    // const resp = await getUploadImageId(file)
    const imageResp = await uploadImageGenerate(data, (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      setUploadSelfProgress(progress);
    });

    // Cookies.set('scripttxt', location?.state?.subFormData?.question, { path: '/', domain: '.yatter.io' });
    Cookies.set('scripttxt', getformData.subformData.question, { path: '/', domain: '.yatter.io' });
    // Cookies.set('scripttxt', getformData.subformData.question);
    const imgData = {
      url: imageResp.url,
      type: 'upload'
    }
    // setitems([...items, imageResp]);
    setitems([...items, imgData]);
    // setUploadSelfLoader(false)
    setUploadSelfLoader(null)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Store the Base64 string in local storage
        localStorage.setItem('uploadedImage', reader.result);
      };
      reader.readAsDataURL(file);
    }

  };
  const handleAddFileChange = async (event) => {
    if (items.length < 5) {
      setUploadAddLoader(true)
      const file = event.target?.files[0];
      // console.log('fileee', file);
      console.log('nisaldhpowe', file);
      let data = new FormData();
      data.append('image', file);
      // // const imageResp = await uploadImageGenerate(data);
      const imageResp = await uploadImageGenerate(data, (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadAddProgress(progress);
      });
      const imgData = {
        url: imageResp.url,
        type: 'upload'
      }
      setitems([...items, imgData]);
      // setitems([...items, imageResp]);
      Cookies.set('scripttxt', getformData.subformData.question, { path: '/', domain: '.yatter.io' });
      // Cookies.set('scripttxt', getformData.subformData.question);
      // setUploadAddLoader(false)
      setUploadAddLoader(null)
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Store the Base64 string in local storage
          localStorage.setItem('uploadedImage', reader.result);
        };
        reader.readAsDataURL(file);
      }

    } else {
      setImageLimit(true)
    }

  };


  const handleFileChangesss = (index) => async (event) => {
    setUploadProgress(null)
    setUploadLoader(index)
    const file = event.target.files[0];
    let data = new FormData();
    data.append('image', file);
    const imageResp = await uploadImageGenerate(data, (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      setUploadProgress(progress);
    });
    const updatedImages = [...items];
    const imgData = {
      url: imageResp.url,
      type: 'upload'
    }
    // updatedImages[index] = imageResp;
    updatedImages[index] = imgData;
    setitems(updatedImages);
    Cookies.set('scripttxt', getformData.subformData.question, { path: '/', domain: '.yatter.io' });
    // Cookies.set('scripttxt', getformData.subformData.question);
    setUploadLoader(null)
    // setUploadLoader(false)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Store the Base64 string in local storage
        localStorage.setItem('uploadedImage', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = (index) => {
    if (uploadLoader === null) {
      setHoveredIndex(false)
    }
  };

  const handleMouseAddEnter = () => {
    if (uploadAddLoader != null || uploadAddProgress === 100) {
      setAddHoveredIndex(true);
    } else {
      setAddHoveredIndex(true);
    }
  };

  const handleMouseAddLeave = () => {
    if (uploadAddLoader === null) {
      setAddHoveredIndex(false);
    }
  };



  const openModal = (event, imgSrc) => {
    event.preventDefault();
    setModalImage(imgSrc);
  }

  const closeModal = () => {
    setModalImage(null);
  };


  function navigateToVideo(items) {
    navigate('/GetAudio', { state: { items } });
  }


  const [subformData, setSubFormData] = useState({
    // image: "",
    question: '',
  });


  // useEffect(() => {
  //   // setitems(image);
  //   return console.log('itemsss', items);
  //   // setitems(location.state.caption)
  //   // console.log('nmxpweh', location.state);
  //   if (location.state && location.state.response) {
  //     console.log('within state IF');
  //     // items = location.state.response || []; // Accessing data passed from Screen 1
  //     setitems(location.state.response);
  //     console.log('wdq', items);
  //     // setitems([...items, location.state.response]);
  //     // sendDataToWebService (items);
  //     /*const navigateToVideo = () => {
  //       //  const dataToPass = { items };
  //       navigate('/GetAudio', { state: { items } });
  //     }; */
  //   }
  //   //  const scriptout = localStorage.getItem('scripttxt');
  //   const scriptout = Cookies.get('scripttxt');
  //   console.log('cookie in getimages');
  //   console.log(scriptout);
  //   if (scriptout) {
  //     setSubFormData({ "question": scriptout });
  //   }
  // }, []);

  function formatMilliseconds(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${paddedSeconds}`;
  }
  console.log('iteenn', items?.[0]);
  var forreadyimage = '';

  async function handleRegenerate(indexData) {
    if (items.length <= 5) {
      setRegenerateLoader(indexData)
      // console.log('index', indexData);
      const totalDuration = 40000;
      // const totalDuration = 150000;
      let elapsedTime = 0;
      const interval = setInterval(() => {
        elapsedTime += 1000;
        const calculatedProgress = (elapsedTime / totalDuration) * 100;
        setReGenProgress(calculatedProgress);

        //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
        const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
        const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

        setTimeRemaining(calculatedTimeRemaining);

        if (elapsedTime >= totalDuration) {

          console.log('forreadyimage');
          console.log('forreadyimage');
          if (forreadyimage == '') {
            console.log("data not ready");
            elapsedTime = 0;
            setRegenerateLoader(null);
          }
          else {
            setReGenProgress(100);
            clearInterval(interval);
            settimeCompleted(true);
            setReGenProgress(100);
            setRegenerateLoader(null)
            //  navigate('/GetImages', { state: { forreadyvideo } });
          }


        }
      }, 1000);
      // setLoading(true); // Start loading
      const formData = {
        question: getformData.subformData.question,
        qabout: textAreaValue
      }

      try {
        // const response = await generateEssay(formData);
        // setProgress(0);
        // console.log('too much 2');
        // console.log(formData);
        const response = await generateImage(formData);
        // const response = await generateImage(location.state.subFormData);
        // const response = await generateImage(location.state.subFormData, (progressEvent) => {
        //   const progress = (progressEvent.loaded / progressEvent.total) * 100;
        //   console.log('porrr', progress);
        //   setReGenProgress(progress);
        // });
        // const response = await generateImage(location.state.subFormData);
        // const response = await maskImage(formData);
        // return console.log('resp', response);
        //console.log("in app");
        //console.log(response);
        console.log('respnosessss', response);
        if (response) {
          // setLoading(false); // Start loading
          // navigate('/GetImages', { state: { response } });
          // return setImage(response);
          // setitems(response);
          // const newImageData = items[indexData] = response;
          const imagData = {
            url: response?.[0],
            type: "generate"
          }
          const updatedImages = [...items];
          updatedImages[indexData] = imagData;
          // updatedImages[indexData] = response?.[0];
          // setitems(updatedImages);
          // console.log(updatedImages);
          // const updatedImages = items.map((item, index) => (
          //   index === indexData ? response : item
          // ));

          // // Update the state with the modified array
          // setitems(updatedImages);
          // setReGenProgress(null)
          // console.log('in items response', updatedImages);
          setregeneratecount(prevCount => prevCount + 1);
          forreadyimage = response;
          //  setLoading(false); // Start loading
          forreadyimage = response;
          let timerem = totalDuration - elapsedTime;
          // console.log(timerem);

          setitems(updatedImages);

          // setTimeout(() => {
          //     navigate('/GetImages', {
          //         state: {
          //             response: response, // your response data
          //             subFormData: subformData // your additional data
          //         }
          //     });
          // }, timerem); // 2000 milliseconds = 2 seconds
          //console.log(items);
          /* items=response;
           console.log('in items respnose');
           console.log(items);
           */
        } else {
          // setReGenProgress(null)
          return console.log('error in regenerate');
        }
      } catch (error) {
        // setReGenProgress(null)
        console.error('Error handling form submission:', error);
      }

    } else {
      setImageLimit(true)
    }
  };


  async function handleFirstRegenerate() {

    if (items.length <= 5) {
      setUploadSelfLoader(true)
      setFirstRegenerate(true)
      // console.log('index', indexData);
      const totalDuration = 40000;
      // const totalDuration = 150000;
      let elapsedTime = 0;
      const interval = setInterval(() => {
        elapsedTime += 1000;
        const calculatedProgress = (elapsedTime / totalDuration) * 100;
        setUploadSelfProgress(calculatedProgress);

        //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
        const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
        const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

        setTimeRemaining(calculatedTimeRemaining);

        if (elapsedTime >= totalDuration) {

          // console.log('forreadyimage');
          // console.log('forreadyimage');
          if (forreadyimage == '') {
            console.log("data not ready");
            elapsedTime = 0;
            // setRegenerateLoader(null);
            setUploadSelfLoader(true)
          }
          else {
            // setReGenProgress(100);
            setUploadSelfProgress(100)
            clearInterval(interval);
            settimeCompleted(true);
            // setReGenProgress(100);
            setUploadSelfProgress(100)
            // setRegenerateLoader(null)
            setUploadSelfLoader(false)
            setUploadSelfLoader(null)
            handleResponse()
            //  navigate('/GetImages', { state: { forreadyvideo } });
          }


        }
      }, 1000);
      // setLoading(true); // Start loading
      const formData = {
        question: getformData.subformData.question,
        qabout: textAreaValue
      }

      try {
        const response = await generateImage(formData);

        console.log('respnosessss', response);
        if (response) {
          // setitems([...items, response]);

          // setregeneratecount(prevCount => prevCount + 1);
          forreadyimage = response;
          //  setLoading(false); // Start loading
          forreadyimage = response;
          let timerem = totalDuration - elapsedTime;
          console.log('forreadyimage', forreadyimage?.[0].url);

          // setitems(updatedImages);
          // if (timeRemaining===100) {
          // setitems([...items, response?.[0]]);
          // }

          console.log(timeRemaining);
        } else {
          // setReGenProgress(null)
          return console.log('error in regenerate');
        }
      } catch (error) {
        // setReGenProgress(null)
        console.error('Error handling form submission:', error);
      }


      function handleResponse() {
        const imagData = {
          url: forreadyimage?.[0].url,
          type: "generate"
        }
        const updatedImages = [...items, imagData];
        // const updatedImages = [...items, forreadyimage?.[0]];
        setitems(updatedImages);
        console.log('forreadyimage', forreadyimage);
      }

    } else {
      setImageLimit(true)
    }
    // return console.log('check', getformData.subformData.question);

  };


  async function handleRegenerate() {

    if (items.length < 5) {

      setUploadAddLoader(true)
      // console.log('index', indexData);
      const totalDuration = 40000;
      // const totalDuration = 150000;
      let elapsedTime = 0;
      const interval = setInterval(() => {
        elapsedTime += 1000;
        const calculatedProgress = (elapsedTime / totalDuration) * 100;
        setUploadAddProgress(calculatedProgress);

        //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
        const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
        const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

        setTimeRemaining(calculatedTimeRemaining);

        if (elapsedTime >= totalDuration) {

          // console.log('forreadyimage');
          // console.log('forreadyimage');
          if (forreadyimage == '') {
            console.log("data not ready");
            elapsedTime = 0;
            setUploadAddLoader(true)
          }
          else {
            // setReGenProgress(100);
            setUploadAddProgress(100)
            clearInterval(interval);
            settimeCompleted(true);
            // setReGenProgress(100);
            setUploadAddProgress(100)
            // setRegenerateLoader(null)
            // setUploadAddLoader(false)
            setUploadAddLoader(null)
            handleResponse()
            //  navigate('/GetImages', { state: { forreadyvideo } });
          }


        }
      }, 1000);
      // setLoading(true); // Start loading
      const formData = {
        // question: location.state.subFormData.question,
        question: getformData.subformData.question,
        qabout: getformData.subformData.qabout
      }

      try {
        const response = await generateImage(formData);

        console.log('respnosessss', response);
        if (response) {
          // setitems([...items, response]);

          // setregeneratecount(prevCount => prevCount + 1);
          forreadyimage = response;
          //  setLoading(false); // Start loading
          forreadyimage = response;
          let timerem = totalDuration - elapsedTime;
          console.log('forreadyimage', forreadyimage);

          // setitems(updatedImages);
          // if (timeRemaining===100) {
          // setitems([...items, response?.[0]]);
          // }

          console.log(timeRemaining);
        } else {
          // setReGenProgress(null)
          return console.log('error in regenerate');
        }
      } catch (error) {
        // setReGenProgress(null)
        console.error('Error handling form submission:', error);
      }

      function handleResponse() {
        const imagData = {
          url: forreadyimage?.[0]?.url,
          type: "generate"
        }
        const updatedImages = [...items, imagData];
        // const updatedImages = [...items, forreadyimage?.[0]];
        setitems(updatedImages);
        console.log('forreadyimage', forreadyimage);
      }
    }
    else {
      setImageLimit(true)
    }

  };

  async function handleSelfRegenerate(indexData) {

    setUploadLoader(indexData)
    // console.log('index', indexData);
    const totalDuration = 40000;
    // const totalDuration = 150000;
    let elapsedTime = 0;
    const interval = setInterval(() => {
      elapsedTime += 1000;
      const calculatedProgress = (elapsedTime / totalDuration) * 100;
      // setReGenProgress(calculatedProgress);
      setUploadProgress(calculatedProgress);

      //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
      const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
      const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

      setTimeRemaining(calculatedTimeRemaining);

      if (elapsedTime >= totalDuration) {

        console.log('forreadyimage');
        console.log('forreadyimage');
        if (forreadyimage == '') {
          console.log("data not ready");
          elapsedTime = 0;
          //   setRegenerateLoader(null);
          // setUploadLoader(null)
          setUploadLoader(indexData)
        }
        else {
          // setReGenProgress(100);
          setUploadProgress(100)
          clearInterval(interval);
          settimeCompleted(true);
          // setReGenProgress(100);
          setUploadProgress(100)
          // setRegenerateLoader(null)
          setUploadLoader(null)
          handleResponse()
          //  navigate('/GetImages', { state: { forreadyvideo } });
        }


      }
    }, 1000);
    // setLoading(true); // Start loading
    const formData = {
      question: textAreaValue,
      qabout: getformData.subformData.question
    }
    console.log(formData);
    try {
      const response = await generateImage(formData);
      // const response = await generateImage(location.state.subFormData);
      // const response = await generateImage(location.state.subFormData, (progressEvent) => {
      //   const progress = (progressEvent.loaded / progressEvent.total) * 100;
      //   console.log('porrr', progress);
      //   setReGenProgress(progress);
      // });
      // const response = await generateImage(location.state.subFormData);
      // const response = await maskImage(formData);
      // return console.log('resp', response);
      //console.log("in app");
      //console.log(response);
      console.log('respnosessss', response);
      if (response) {
        // setLoading(false); // Start loading
        // navigate('/GetImages', { state: { response } });
        // return setImage(response);
        // setitems(response);
        // const newImageData = items[indexData] = response;

        // setitems(updatedImages);
        // console.log(updatedImages);
        // const updatedImages = items.map((item, index) => (
        //   index === indexData ? response : item
        // ));

        // // Update the state with the modified array
        // setitems(updatedImages);
        // setReGenProgress(null)
        // console.log('in items response', updatedImages);
        setregeneratecount(prevCount => prevCount + 1);
        forreadyimage = response;
        //  setLoading(false); // Start loading
        forreadyimage = response;
        let timerem = totalDuration - elapsedTime;

        // setTimeout(() => {
        //     navigate('/GetImages', {
        //         state: {
        //             response: response, // your response data
        //             subFormData: subformData // your additional data
        //         }
        //     });
        // }, timerem); // 2000 milliseconds = 2 seconds
        //console.log(items);
        /* items=response;
         console.log('in items respnose');
         console.log(items);
         */
      } else {
        // setReGenProgress(null)
        return console.log('error in regenerate');
      }
    } catch (error) {
      // setReGenProgress(null)
      console.error('Error handling form submission:', error);
    }

    function handleResponse() {
      const imagData = {
        url: forreadyimage?.[0]?.url,
        type: "generate"
      }
      const updatedImages = [...items];
      updatedImages[indexData] = imagData;
      // updatedImages[indexData] = forreadyimage?.[0];
      // const updatedImages = [...items, forreadyimage?.[0]];
      setitems(updatedImages);
      console.log('forreadyimage', forreadyimage);

    }



    // setRegenerateLoader(indexData)


  };




  const handleTextAreaChange = (e) => { // Step 2
    setTextAreaValue(e.target.value);
  };

  const handleTrashClick = (indexToRemove) => {
    console.log('Trash button clicked');
    setitems((currentItems) => {
      // Create a new array without the item at indexToRemove
      return currentItems.filter((item, index) => index !== indexToRemove);
    });
    // Add your trash click handling logic here
  };





  const handleContinue = (items) => {
    // const captiondata = { captionData: location.state.caption }
    // const updatedValue = { ...items, captionData: getformData?.caption || location.state.caption };
    const updatedValue = { ...items };
    const stateValue = { ...items, firstRegenerate };
    localStorage.setItem('imagePageState', JSON.stringify(stateValue));
    // let updatedValue = []
    // updatedValue = [...items, location.state.caption]
    // updatedValue.push(items, location.state.caption);
    // updatedValue.push(items, { captionData: location.state.caption });
    console.log('smpow', updatedValue)
    navigateToVideo(updatedValue)
    // const updatedData = { ...items, location.state.caption };
    // navigateToVideo(items)
  }

  useEffect(() => {
    const scriptout = Cookies.get('scripttxt');
    console.log('SABDGSQ', scriptout);
    const savedStateString = localStorage.getItem('imagePageState');
    console.log('Raw savedState from localStorage:', savedStateString);

    if (savedStateString) {
      setCheckData(savedStateString)
      try {
        // Parse the JSON string to an object
        const savedStateObject = JSON.parse(savedStateString);
        console.log('Parsed savedStateObject:', savedStateObject);

        // Access the 'firstRegenerate' property correctly
        if ('firstRegenerate' in savedStateObject) {
          setFirstRegenerate(savedStateObject.firstRegenerate);
        } else {
          console.error("firstRegenerate property is missing in the saved state object.");
        }

        // Assuming you want to extract all URL items into an array
        const itemsArray = Object.values(savedStateObject).filter(item => typeof item === 'object' && 'url' in item);
        setitems(itemsArray);
      } catch (error) {
        console.error("Error parsing savedStateString or accessing properties:", error);
      }
    }
  }, []);

  // useEffect(() => {
  //   // Reset state whenever the location changes to this component's path
  //   if (location.pathname === '/GetImages') {
  //     setitems([]); // Clear the state
  //     setFirstRegenerate(false)
  //     localStorage.removeItem('imagePageState');
  //     setCheckData()

  //   }
  // }, [location]);

  // useEffect(() => {
  //   // Function to clear the items state
  //   const clearItems = () => {
  //     setitems([]); // Set items to an empty array
  //   };

  //   // Event listener for the popstate event
  //   const handlePopState = () => {
  //     clearItems(); // Call the function to clear items
  //   };

  //   // Add the event listener for popstate
  //   window.addEventListener('popstate', handlePopState);

  //   // Cleanup function to remove the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, []);

  useEffect(() => {
    // Check local storage for the clearData flag
    const shouldClearData = localStorage.getItem('clearData') === 'true';
    if (shouldClearData) {
      // Clear local state based on the flag
      setitems([]); // Clear the state
      setFirstRegenerate(false)
      localStorage.removeItem('imagePageState');
      setCheckData()

      // Optionally, reset or remove the flag to prevent clearing data on subsequent loads
      localStorage.removeItem('clearData');
    }
  }, []);

  useEffect(() => {
    console.log('length check', items.length);
    return
    if (items.length === 0) {
      setFirstRegenerate(false)
    }
  }, [items])

  // const handleFirstRegenerate = async () => {

  //   setUploadSelfLoader(true)
  //   setFirstRegenerate(true)
  //   // console.log('index', indexData);
  //   const totalDuration = 80000;
  //   // const totalDuration = 150000;
  //   let elapsedTime = 0;
  //   const interval = setInterval(() => {
  //     elapsedTime += 1000;
  //     const calculatedProgress = (elapsedTime / totalDuration) * 100;
  //     setUploadSelfProgress(calculatedProgress);

  //     //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
  //     const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
  //     const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

  //     setTimeRemaining(calculatedTimeRemaining);

  //     if (elapsedTime >= totalDuration) {

  //       // console.log('forreadyimage');
  //       // console.log('forreadyimage');
  //       if (forreadyimage == '') {
  //         console.log("data not ready");
  //         elapsedTime = 0;
  //         // setRegenerateLoader(null);
  //         setUploadSelfLoader(true)
  //       }
  //       else {
  //         // setReGenProgress(100);
  //         setUploadSelfProgress(100)
  //         clearInterval(interval);
  //         settimeCompleted(true);
  //         // setReGenProgress(100);
  //         setUploadSelfProgress(100)
  //         // setRegenerateLoader(null)
  //         setUploadSelfLoader(false)
  //         setUploadSelfLoader(null)
  //         handleResponse()
  //         //  navigate('/GetImages', { state: { forreadyvideo } });
  //       }


  //     }
  //   }, 1000);

  //   const data = {
  //     prompt: location.state.subFormData.question
  //   }
  //   const resp = await imageGenerateID(data)
  //   if (resp) {
  //     // console.log("resp", resp);
  //     // generateImageuRL(resp)
  //     setTimeout(async () => {
  //       // generateImageuRL(resp);
  //       const response = await imageGenerate(resp)
  //       if (response) {
  //         console.log('img', response);
  //         forreadyimage = response?.generations_by_pk.generated_images
  //         //  setLoading(false); // Start loading
  //         // forreadyimage = response;
  //         let timerem = totalDuration - elapsedTime;
  //         console.log('forreadyimage', forreadyimage);
  //       } else {
  //         console.log('img_err', response);
  //       }
  //     }, 60000); // 60000 milliseconds = 1 minute

  //   } else {
  //     console.log("resp_error", resp);
  //   }

  //   function handleResponse() {
  //     // const updatedImages = [...items, forreadyimage?.[0]];
  //     const updatedImages = forreadyimage.map(image => ({ url: image.url }));
  //     // return console.log(updatedImages);
  //     setitems(updatedImages);
  //     console.log('forreadyimage', forreadyimage);
  //   }

  // }
  // const handleSelfRegenerate = async (indexData) => {

  //   setUploadLoader(indexData)
  //   // console.log('index', indexData);
  //   const totalDuration = 80000;
  //   // const totalDuration = 150000;
  //   let elapsedTime = 0;
  //   const interval = setInterval(() => {
  //     elapsedTime += 1000;
  //     const calculatedProgress = (elapsedTime / totalDuration) * 100;
  //     // setReGenProgress(calculatedProgress);
  //     setUploadProgress(calculatedProgress);

  //     //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
  //     const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
  //     const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

  //     setTimeRemaining(calculatedTimeRemaining);

  //     if (elapsedTime >= totalDuration) {

  //       console.log('forreadyimage');
  //       console.log('forreadyimage');
  //       if (forreadyimage == '') {
  //         console.log("data not ready");
  //         elapsedTime = 0;
  //         //   setRegenerateLoader(null);
  //         // setUploadLoader(null)
  //         setUploadLoader(indexData)
  //       }
  //       else {
  //         // setReGenProgress(100);
  //         setUploadProgress(100)
  //         clearInterval(interval);
  //         settimeCompleted(true);
  //         // setReGenProgress(100);
  //         setUploadProgress(100)
  //         // setRegenerateLoader(null)
  //         setUploadLoader(null)
  //         handleResponse()
  //         //  navigate('/GetImages', { state: { forreadyvideo } });
  //       }


  //     }
  //   }, 1000);

  //   const data = {
  //     prompt: textAreaValue
  //   }
  //   const resp = await imageGenerateID(data)
  //   if (resp) {
  //     // console.log("resp", resp);
  //     // generateImageuRL(resp)
  //     setTimeout(async () => {
  //       // generateImageuRL(resp);
  //       const response = await imageGenerate(resp)
  //       if (response) {
  //         console.log('img', response);
  //         setregeneratecount(prevCount => prevCount + 1);
  //         forreadyimage = response?.generations_by_pk.generated_images
  //         //  setLoading(false); // Start loading
  //         // forreadyimage = response;
  //         let timerem = totalDuration - elapsedTime;
  //         console.log('forreadyimage', forreadyimage);
  //       } else {
  //         console.log('img_err', response);
  //       }
  //     }, 60000); // 60000 milliseconds = 1 minute

  //   } else {
  //     console.log("resp_error", resp);
  //   }

  //   function handleResponse() {
  //     const updatedImages = [...items];
  //     // const updatedImages = forreadyimage.map(image => ({ url: image.url }));
  //     // forreadyimage?.[0];
  //     updatedImages[indexData] = { url: forreadyimage?.[0]?.url };
  //     // const updatedImages = [...items, forreadyimage.map(image => ({ url: image.url }))];
  //     setitems(updatedImages);
  //     console.log('forreadyimage', forreadyimage);

  //   }

  // }
  // const handleRegenerate = async () => {

  //   setUploadAddLoader(true)
  //   // console.log('index', indexData);
  //   const totalDuration = 80000;
  //   // const totalDuration = 150000;
  //   let elapsedTime = 0;
  //   const interval = setInterval(() => {
  //     elapsedTime += 1000;
  //     const calculatedProgress = (elapsedTime / totalDuration) * 100;
  //     setUploadAddProgress(calculatedProgress);

  //     //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
  //     const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
  //     const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

  //     setTimeRemaining(calculatedTimeRemaining);

  //     if (elapsedTime >= totalDuration) {

  //       // console.log('forreadyimage');
  //       // console.log('forreadyimage');
  //       if (forreadyimage == '') {
  //         console.log("data not ready");
  //         elapsedTime = 0;
  //         setUploadAddLoader(true)
  //       }
  //       else {
  //         // setReGenProgress(100);
  //         setUploadAddProgress(100)
  //         clearInterval(interval);
  //         settimeCompleted(true);
  //         // setReGenProgress(100);
  //         setUploadAddProgress(100)
  //         // setRegenerateLoader(null)
  //         // setUploadAddLoader(false)
  //         setUploadAddLoader(null)
  //         handleResponse()
  //         //  navigate('/GetImages', { state: { forreadyvideo } });
  //       }


  //     }
  //   }, 1000);

  //   const data = {
  //     prompt: location.state.subFormData.question
  //   }
  //   const resp = await imageGenerateID(data)
  //   if (resp) {
  //     // console.log("resp", resp);
  //     // generateImageuRL(resp)
  //     setTimeout(async () => {
  //       // generateImageuRL(resp);
  //       const response = await imageGenerate(resp)
  //       if (response) {
  //         console.log('img', response);
  //         forreadyimage = response?.generations_by_pk.generated_images
  //         //  setLoading(false); // Start loading
  //         // forreadyimage = response;
  //         let timerem = totalDuration - elapsedTime;
  //         console.log('forreadyimage', forreadyimage);
  //       } else {
  //         console.log('img_err', response);
  //       }
  //     }, 60000); // 60000 milliseconds = 1 minute

  //   } else {
  //     console.log("resp_error", resp);
  //   }

  //   function handleResponse() {
  //     const updatedImages = [...items, { url: forreadyimage?.[0].url }];
  //     setitems(updatedImages);
  //     console.log('forreadyimage', forreadyimage);
  //   }

  // }




  // const generateImageuRL = async (id) => {
  //   // console.log(id);
  //   // setUploadSelfLoader(true)
  //   // setFirstRegenerate(true)
  //   // console.log('index', indexData);
  //   const totalDuration = 10000;
  //   // const totalDuration = 150000;
  //   let elapsedTime = 0;
  //   const interval = setInterval(() => {
  //     elapsedTime += 1000;
  //     const calculatedProgress = (elapsedTime / totalDuration) * 100;
  //     setUploadSelfProgress(calculatedProgress);

  //     //const calculatedTimeRemaining = (totalDuration - elapsedTime) / 60000;
  //     const calculatedTimeRemaining1 = (totalDuration - elapsedTime);
  //     const calculatedTimeRemaining = formatMilliseconds(calculatedTimeRemaining1);

  //     setTimeRemaining(calculatedTimeRemaining);

  //     if (elapsedTime >= totalDuration) {

  //       // console.log('forreadyimage');
  //       // console.log('forreadyimage');
  //       if (forreadyimage == '') {
  //         console.log("data not ready");
  //         elapsedTime = 0;
  //         // setRegenerateLoader(null);
  //         setUploadSelfLoader(true)
  //       }
  //       else {
  //         // setReGenProgress(100);
  //         setUploadSelfProgress(100)
  //         clearInterval(interval);
  //         settimeCompleted(true);
  //         // setReGenProgress(100);
  //         setUploadSelfProgress(100)
  //         // setRegenerateLoader(null)
  //         setUploadSelfLoader(false)
  //         setUploadSelfLoader(null)
  //         handleResponse()
  //         //  navigate('/GetImages', { state: { forreadyvideo } });
  //       }


  //     }
  //   }, 1000);
  //   const response = await imageGenerate(id)
  //   if (response) {
  //     console.log('img', response);
  //     forreadyimage = response?.generations_by_pk.generated_images
  //     //  setLoading(false); // Start loading
  //     // forreadyimage = response;
  //     let timerem = totalDuration - elapsedTime;
  //     console.log('forreadyimage', forreadyimage);
  //   } else {
  //     console.log('img_err', response);
  //   }
  //   function handleResponse() {
  //     const updatedImages = [...items, forreadyimage?.[0]];
  //     setitems(updatedImages);
  //     console.log('forreadyimage', forreadyimage);
  //   }
  // }



  return (
    <>
      <div className="creator-wrapper">
        <h3 className="mb-4">AI Image</h3>

        <div className="progress-steps">

          <div className="step-block completed"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ cursor: hover && 'pointer' }}
            onClick={() => {
              hover && navigate('/GetScript')
              // setitems([])
              localStorage.removeItem('imagePageState');
            }}

          >
            <span className="step-label" style={{ color: hover && '#F11D28' }}>1. Choose Script</span>
            <span className="step-indicator" style={{ transform: hover && 'scale(1.06)' }}><span className="material-icons check-icon">check</span></span>
          </div>
          <div
            className="step-block active"

          >
            <span className="step-label">2. Choose Image</span>
            <span className="step-indicator"><span className="material-icons check-icon">check</span></span>
          </div>
          <div className="step-block">
            <span className="step-label">3. Choose Video</span>
            <span className="step-indicator"><span className="material-icons check-icon">check</span></span>
          </div>

        </div>


        {
          firstRegenerate != true &&
          <form class="creator-form" action="#">
            <label for="insert-script" class="label">Which images will you use for your video?</label>
            <div class="choose-img-inputs">
              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-gray btn-sm"
                  // onClick={generateImageData}
                  onClick={handleFirstRegenerate}
                >
                  Generate with AI
                </button>
                <div class="mb-4 mt-4">
                  <span class="f-18">OR</span>
                </div>

                <div className='btn_main'>
                  <button type="button" class="btn btn-gray btn-sm" style={{ cursor: "pointer" }} >Upload your own
                  </button>

                  <input class="btn btn-gray btn-sm" type="file" onChange={(event) => handleFileChange(event)} />

                </div>
                {/* <button onClick={handleFileChange} type="button" class="btn btn-gray btn-sm">Choose from device</button> */}
              </div>
            </div>
            {/* <!-- choose-img-inputs--> */}


            {/* <!-- choose-img-inputs--> */}
            <button type="button" class="btn btn-gradient">Continue</button>
          </form>
        }
        {/* {regenProgress != null && regenProgress < 100 && (


          <div class="choose-img-inputs">
            <div class="w-full">
              <p class="font-bold mb-3">Generating image...</p>
              <div class="flex items-center w-100">
                <div class="progress-bar ml-4">
                  <div class="progress" style={{ width: `${regenProgress}%` }}></div>
                </div>
                <h4 class="ml-4" >{regenProgress.toFixed(0)}%</h4>
              </div>
            </div>
          </div>
        )} */}

        {/* {uploadSelfLoader && uploadProgress != null && uploadProgress < 100 && ( */}
        {uploadSelfLoader && uploadSelfProgress != null && (

          <div class="choose-img-inputs">
            <div class="w-full">
              <p class="font-bold mb-3">Uploading image...</p>
              <div class="flex items-center w-100">
                <div class="progress-bar ml-4">
                  <div class="progress" style={{ width: `${uploadSelfProgress}%` }}></div>
                </div>
                <h4 class="ml-4" >{uploadSelfProgress.toFixed(0)}%</h4>
              </div>
            </div>
          </div>


        )}

        {uploadSelfProgress === 100 && items?.length > 0 &&
          <>
            <label htmlfor="insert-script" className="label">Generated Images</label>

            <div className="generated-images">
              {


                items?.map((item, index) => (
                  <>
                    <div class="generated-img-block" key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        key={index}
                        src={item.url}
                        className='imgzoom'
                        alt="Generated from DALL-E"
                        width={200}
                        height={200}
                        onClick={() => setSelectedImg(item.url)}
                      />
                      {hoveredIndex === index && (
                        <>
                          {console.log(item)}
                          <button onClick={() => handleTrashClick(index)} className="icon-button" style={{ position: "absolute", top: -10, right: 4 }}>
                            <img style={{ width: 24, height: 24, margin: 0, border: 'none' }} src={trash} alt="dlete_btn" />
                          </button>

                          <div class="custom-tooltip regenerate-action active">

                            {
                              regenerate === index ?
                                <div>

                                  <button
                                    class="btn btn-white btn-sm absolute bottom-4 right-3"
                                    type="button"
                                    onClick={() =>
                                      setRegenerate(null)

                                    }
                                  >
                                    ←
                                  </button>

                                  <p style={{ color: "white", fontSize: 10 }}>Describe your image how you want it</p>
                                  <textarea
                                    name="enter desc" rows={4}
                                    cols={40}
                                    style={{
                                      fontSize: 10,
                                      color: "white",
                                      backgroundColor:
                                        "transparent",
                                      border: '1px solid white',
                                      borderRadius: 5,
                                      marginTop: 3,
                                      paddingLeft: 5,
                                      paddingTop: 5
                                    }}
                                    value={textAreaValue}
                                    onChange={handleTextAreaChange}
                                  />
                                  <button
                                    class="btn btn-white btn-sm"
                                    type="button"
                                    disabled={regeneratecount > 0 && true}
                                    onClick={() => {
                                      handleSelfRegenerate(index);
                                      setRegenerate(null);
                                    }}

                                  >
                                    Submit
                                  </button>

                                </div>
                                :
                                <div class="text-center">
                                  <button
                                    class="btn btn-white btn-sm"
                                    type="button"
                                    disabled={regeneratecount > 0 && true}
                                    onClick={() =>
                                      setRegenerate(index)

                                    }
                                  >
                                    {item?.type === "upload" ? 'Generate with AI' : 'Regenerate'}
                                  </button>
                                  <div class="flex justify-center mt-3 mb-3">
                                    <span class="f-12 text-light-gray flex items-center"><span class="material-symbols-outlined mr-1 f-18">info</span> You can only regenerate image once</span>
                                  </div>
                                  <div class="mt-2 mb-2">
                                    <span class="text-white">OR</span>
                                  </div>

                                  <div className='btn_main'>
                                    <button type="button" class="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                                    </button>

                                    <input class="btn btn-white btn-sm sxs" type="file" onChange={handleFileChangesss(index)} />

                                  </div>

                                  {uploadLoader === index && uploadProgress != null && (
                                    <div class="flex items-center w-100  mt-2">
                                      <div class="progress-bar ml-4">
                                        <div class="progress" style={{ width: `${uploadProgress}%` }}></div>
                                      </div>
                                      <h4 class="ml-4" style={{ color: "white" }}>{uploadProgress.toFixed(0)}%</h4>
                                    </div>
                                  )}
                                </div>

                            }


                          </div>

                        </>

                      )}

                    </div>

                  </>

                ))

                // )
              }
              {
                items.length < 5 &&

                <div class="generated-img-block blank"
                  onMouseEnter={handleMouseAddEnter}
                  onMouseLeave={handleMouseAddLeave}
                >
                  <div class="text-center">
                    <span class="material-symbols-outlined text-gray mb-4">cloud_upload</span>
                    <p class="text-gray m-0">Add another image</p>
                  </div>
                  {addhoveredIndex && (
                    <>

                      <div class="custom-tooltip regenerate-action active">

                        <div class="text-center">
                          <button
                            class="btn btn-white btn-sm"
                            type="button"
                            onClick={handleRegenerate}
                          // onClick={() =>
                          //   setRegenerate()
                          // }
                          >
                            Generate with AI
                          </button>
                          <div class="mt-2 mb-2">
                            <span class="text-white">OR</span>
                          </div>

                          <div className='btn_main'>
                            <button type="button" class="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                            </button>

                            <input
                              class="btn btn-white btn-sm"
                              type="file"
                              onChange={(event) => handleAddFileChange(event)}
                            // onChange={() => console.log('File input clicked')}
                            />

                          </div>

                          {uploadAddLoader && uploadAddProgress != null && (
                            <div class="flex items-center w-100  mt-2">
                              <div class="progress-bar ml-4">
                                <div class="progress" style={{ width: `${uploadAddProgress}%` }}></div>
                              </div>
                              <h4 class="ml-4" style={{ color: "white" }}>{uploadAddProgress.toFixed(0)}%</h4>
                            </div>

                          )}
                        </div>



                      </div>

                    </>
                    // <div className="popup" style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: '#fff' }}>
                    //   {regeneratecount == 0 ? (
                    //     <a href="#" className="f-14 text-gray flex items-center mb-2" onClick={() => handleFormSubmit(index)}><span className="material-icons mr-1 f-20">restart_alt</span> Regenerate</a>
                    //   ) : (
                    //     <p></p>
                    //   )}

                    //  
                    // </div>
                  )}
                </div>
              }

            </div>


            <Modal isOpen={selectedImg !== null} imgSrc={selectedImg} onClose={() => setSelectedImg(null)} />



            <div className='loaderbtnscript'><button type="button" className="btn btn-gradient" onClick={() => handleContinue(items)}>Continue</button>{loading ? <div className="loader"></div> : ''}</div>


            {
              imageLimit &&
              <p style={{ color: "red", marginTop: 5 }}>you cannot upload image more than 5 images</p>
            }
            <p style={{ color: "red", marginTop: 8 }}>You can only get two more upload/AI images</p>
          </>
        }

        {checkData &&
          <>
            <label htmlfor="insert-script" className="label">Generated Images</label>

            <div className="generated-images">
              {


                items?.map((item, index) => (
                  <>
                    <div class="generated-img-block" key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        key={index}
                        src={item.url}
                        className='imgzoom'
                        alt="Generated from DALL-E"
                        width={200}
                        height={200}
                        onClick={() => setSelectedImg(item.url)}
                      />
                      {hoveredIndex === index && (
                        <>
                          <button onClick={() => handleTrashClick(index)} className="icon-button" style={{ position: "absolute", top: -10, right: 4 }}>
                            <img style={{ width: 24, height: 24, margin: 0, border: 'none' }} src={trash} alt="dlete_btn" />
                          </button>

                          <div class="custom-tooltip regenerate-action active">

                            {
                              regenerate === index ?
                                <div>

                                  <button
                                    class="btn btn-white btn-sm absolute bottom-4 right-3"
                                    type="button"
                                    onClick={() =>
                                      setRegenerate(null)

                                    }
                                  >
                                    ←
                                  </button>

                                  <p style={{ color: "white", fontSize: 10 }}>Describe your image how you want it</p>
                                  <textarea
                                    name="enter desc" rows={4}
                                    cols={40}
                                    style={{
                                      fontSize: 10,
                                      color: "white",
                                      backgroundColor:
                                        "transparent",
                                      border: '1px solid white',
                                      borderRadius: 5,
                                      marginTop: 3,
                                      paddingLeft: 5,
                                      paddingTop: 5
                                    }}
                                    value={textAreaValue}
                                    onChange={handleTextAreaChange}
                                  />
                                  <button
                                    class="btn btn-white btn-sm"
                                    type="button"
                                    disabled={regeneratecount > 0 && true}
                                    onClick={() => {
                                      handleSelfRegenerate(index);
                                      setRegenerate(null);
                                    }}

                                  >
                                    Submit
                                  </button>

                                </div>
                                :
                                <div class="text-center">
                                  <button
                                    class="btn btn-white btn-sm"
                                    type="button"
                                    disabled={regeneratecount > 0 && true}
                                    onClick={() =>
                                      setRegenerate(index)

                                    }
                                  >
                                    Regenerate
                                  </button>
                                  <div class="flex justify-center mt-3 mb-3">
                                    <span class="f-12 text-light-gray flex items-center"><span class="material-symbols-outlined mr-1 f-18">info</span> You can only regenerate image once</span>
                                  </div>
                                  <div class="mt-2 mb-2">
                                    <span class="text-white">OR</span>
                                  </div>

                                  <div className='btn_main'>
                                    <button type="button" class="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                                    </button>

                                    <input class="btn btn-white btn-sm sxs" type="file" onChange={handleFileChangesss(index)} />

                                  </div>

                                  {uploadLoader === index && uploadProgress != null && (
                                    <div class="flex items-center w-100  mt-2">
                                      <div class="progress-bar ml-4">
                                        <div class="progress" style={{ width: `${uploadProgress}%` }}></div>
                                      </div>
                                      <h4 class="ml-4" style={{ color: "white" }}>{uploadProgress.toFixed(0)}%</h4>
                                    </div>
                                  )}
                                </div>

                            }


                          </div>

                        </>

                      )}

                    </div>

                  </>

                ))

                // )
              }
              {
                items.length < 5 &&

                <div class="generated-img-block blank"
                  onMouseEnter={handleMouseAddEnter}
                  onMouseLeave={handleMouseAddLeave}
                >
                  <div class="text-center">
                    <span class="material-symbols-outlined text-gray mb-4">cloud_upload</span>
                    <p class="text-gray m-0">Add another image</p>
                  </div>
                  {addhoveredIndex && (
                    <>

                      <div class="custom-tooltip regenerate-action active">

                        <div class="text-center">
                          <button
                            class="btn btn-white btn-sm"
                            type="button"
                            onClick={handleRegenerate}
                          // onClick={() =>
                          //   setRegenerate()
                          // }
                          >
                            Generate with AI
                          </button>
                          <div class="mt-2 mb-2">
                            <span class="text-white">OR</span>
                          </div>

                          <div className='btn_main'>
                            <button type="button" class="btn btn-white btn-sm " style={{ cursor: "pointer" }} >Upload your own
                            </button>

                            <input
                              class="btn btn-white btn-sm"
                              type="file"
                              onChange={(event) => handleAddFileChange(event)}
                            />

                          </div>

                          {uploadAddLoader && uploadAddProgress != null && (
                            <div class="flex items-center w-100  mt-2">
                              <div class="progress-bar ml-4">
                                <div class="progress" style={{ width: `${uploadAddProgress}%` }}></div>
                              </div>
                              <h4 class="ml-4" style={{ color: "white" }}>{uploadAddProgress.toFixed(0)}%</h4>
                            </div>

                          )}
                        </div>



                      </div>

                    </>
                    // <div className="popup" style={{ position: 'absolute', border: '1px solid #ccc', padding: '10px', background: '#fff' }}>
                    //   {regeneratecount == 0 ? (
                    //     <a href="#" className="f-14 text-gray flex items-center mb-2" onClick={() => handleFormSubmit(index)}><span className="material-icons mr-1 f-20">restart_alt</span> Regenerate</a>
                    //   ) : (
                    //     <p></p>
                    //   )}

                    //  
                    // </div>
                  )}
                </div>
              }

            </div>


            <Modal isOpen={selectedImg !== null} imgSrc={selectedImg} onClose={() => setSelectedImg(null)} />



            <div className='loaderbtnscript'><button type="button" className="btn btn-gradient" onClick={() => handleContinue(items)}>Continue</button>{loading ? <div className="loader"></div> : ''}</div>

            {
              imageLimit &&
              <p style={{ color: "red", marginTop: 5 }}>you cannot upload image more than 5 images</p>
            }
            <p style={{ color: "red", marginTop: 8 }}>You can only get two more AI images</p>

          </>
        }


        <div className="mb-6">

          {/* {regeneratecount == 0 ? (
            <a href="#" className="f-14 text-gray flex items-center mb-2" onClick={() => handleFormSubmit(subformData)}><span className="material-icons mr-1 f-20">restart_alt</span> Regenerate</a>
          ) : (
            <p></p>
          )} */}



          {/*   <div className="flex items-center mb-2">
                <a href="#" className="f-14 text-gray flex items-center"><span className="material-symbols-outlined mr-1 f-18">arrow_back_ios</span></a>
                <span className="f-14 mr-1">2/2</span>
                <a href="#" className="f-14 text-gray flex items-center"><span className="material-symbols-outlined f-18">arrow_forward_ios</span></a>
              </div>
            */}

          {/* {uploadProgress !== null && (
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: 200
            }}>
              <div class="progress-bar mr-4">
                <div class="progress" style={{ width: `${uploadProgress}%`, }}></div>
              </div>
              <h4>{`${Math.round(uploadProgress)}%`}</h4>
            </div>
            // <div class='progress-bar' style={{
            //   width: 150
            // }} >
            //   <div class="progress" style={{
            //     // backgroundColor: 'green',
            //     width: `${uploadProgress}%`,
            //   }}>
            //     <h4 style={{ paddingTop: 10 }}>
            //       {`${Math.round(uploadProgress)}%`}
            //     </h4>
            //   </div>

            // </div>
          )} */}


          {/* <span
            className="f-12 text-light-gray flex items-center "
          ><span className="material-symbols-outlined mr-1 f-18">info</span> You can only generate images once</span> */}
        </div>

        {/* <div className='loaderbtnscript'><button type="button" className="btn btn-gradient" onClick={() => handleContinue(items)}>Continue</button>{loading ? <div className="loader"></div> : ''}</div> */}
      </div >

      <div className="example-part">
        <h6 className="mb-2">AI Example</h6>
        <div className="example-media">
          {/* <img className="media-file" src={`${process.env.PUBLIC_URL}/images/ai-example.jpg`} alt="Example media"/> */}
          <video width="320" height="240" controls>
            <source src={`${process.env.PUBLIC_URL}/images/TheMaryCeleste.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </>
  );
}

export default GetImages;
